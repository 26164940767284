@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Urbanist', sans-serif;
}

html {
    font-weight: 500;
    font-size: 18px;
}

@media print {
    body {
        font-size: 12pt;
        line-height: 1.5;
    }

    body * {
        visibility: hidden;
      }
      #printable-div, #printable-div * {
        visibility: visible;
      }
      #printable-div {
        position: absolute;
        left: 0;
        top: 0;
      }
}